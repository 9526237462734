.App {
  text-align: center;
  display: block;
}

*{
  font-family: 'DM Sans';
}

.Top-section {
    
    display: flex;
    flex-direction: column;
    
}

p {
  /* Body/Regular */

font-family: 'DM Sans';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 26px;
color: aliceblue;
}


a {
  text-decoration: none;
  color: #121212;;
}

a:active {
  text-decoration: underline;
  color: #1e1c86;;
}



/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

html {
  scroll-behavior: smooth;
}


.up-cursor p {
  color: aliceblue;
}