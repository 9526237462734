.about-app {
    width: 90%;
    margin: auto;

    display: flex;
  flex-direction: column;
}




.body {
    min-height: 300px;
    min-width: 60%;
    max-width: 500px;
    margin: 5% auto;
   padding: 5%;
   z-index: 2;
   border-radius: 10px;
    background-color: rgba(80, 69, 83, 0.85);
}


@media (max-width: 650px) {

    .body {
        margin-top: 10%;
    }

        
    }

.body p {
    color:#ffffff;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;
}

.body h2 {
    font-style: italic;
    font-weight: 700;
    font-size: 35px;
    color:#ffffff;
    opacity: 0.75;
}

 .about-videoback {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    
}


.about-socials a {
    margin: 0% 10px;
}